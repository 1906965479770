var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9d01b8db26dc8e1945f403a48efc961fcdebb333"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, Replay, showReportDialog } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
if (process.env.NODE_ENV === 'production') {
  init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Replay()],

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        event.event_id && showReportDialog({ eventId: event.event_id })
      }
      return event
    },
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver loop limit exceeded',
      "null is not an object (evaluating 'c.drawImage')",
      "Failed to execute 'getImageData' on 'CanvasRenderingContext2D'",
      'timeout of 3000ms exceeded',
      'Network Error',
      "Cannot read properties of undefined (reading 'autoplay')",
      "undefined is not an object (evaluating 'e.params.autoplay')",
      'Error: Error creating WebGL context.',
      'Error: Seems containerElement is not an ancestor',
      'Element type is invalid: expected a string (for built-in components)',
      'TypeError: e.params is undefined',
      "Cannot read properties of null (reading 'outerWidth')",
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
